import React, { FC, useState } from 'react';
import { useTranslation, WidgetProps } from '@wix/yoshi-flow-editor';
import {
  type FormValues,
  type FormError,
  type FormActions,
  type FormResults,
  type FormConfig,
} from '@wix/form-viewer';
import { classes } from './Widget.st.css';
import { FormViewer, FormViewerContext } from '@wix/form-viewer/widget';

export type ControllerProps = {
  formId: string;
  wfActions: FormActions;
  wfConfig: FormConfig;
  wfResults: FormResults;
};

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { i18n } = useTranslation();
  const [formValues, setFormValues] = useState<FormValues>({});
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  return (
    <div className={classes.form} data-hook="OnboardingForm-wrapper">
      <FormViewerContext {...props}>
        <FormViewer
          onChange={setFormValues}
          onValidate={setFormErrors}
          values={formValues}
          errors={formErrors}
          i18n={i18n}
          formId={props.formId}
        />
      </FormViewerContext>
    </div>
  );
};

export default Widget;
